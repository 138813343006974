import React from 'react'

const OffPageMenu = (props) => {
    return (
        <div className="offpage-menu p-4" style={{display: 'none'}}>
	        <span className="flex w-full pb-4 justify-end"><a href="#" id="burger-close">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                    <path d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z"></path></svg></a>
            </span>
            <ul className="list-unstyled">
                <li className="flex mb-4">
                    <a href="http://wtbi.test/who-we-are/" className="text-2xl hover:text-red-600"><strong>Who we are</strong></a>
                </li>
                <li className="flex mb-4">
                    <a href="http://wtbi.test/what-we-do/" className="text-2xl hover:text-red-600"><strong>What We Do</strong></a>
                </li>
                <li className="flex mb-4">
                    <a href="http://wtbi.test/careers/" className="text-2xl hover:text-red-600"><strong>Careers</strong></a></li>
                <li className="flex mb-4">
                    <a href="http://wtbi.test/how-we-work/" className="text-2xl hover:text-red-600"><strong>How we work</strong></a>
                </li>
                <li className="flex mb-4">
                    <a href="/portfolio/" className="text-2xl hover:text-red-600"><strong>Portfolio</strong></a>
                </li>
                <li className="flex mb-4">
                    <a href="/articles/" className="text-2xl hover:text-red-600"><strong>Articles</strong></a>
                </li>
            </ul>
            <span className="text-3xl mt-4">Or call us on - <strong>01536 0000 000</strong></span>
        </div>
    )
}

export default OffPageMenu