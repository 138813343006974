import React from "react"
import {graphql} from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const HomeTemplate = ({data}) => (
    <Layout>
            <SEO title="home"/>
            <h1 className="text-black"></h1>
    </Layout>
)
export default HomeTemplate

export const query = graphql`
{
  wordpressPage(wordpress_id: {eq: 5}) {
    id
    wordpress_id
    type
    title
    slug
    excerpt
    content
    author
  }
}
`

console.log(query)