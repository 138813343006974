import React from "react"

const HeaderMain = (props) => {
    return (
        <>
            <div className="flex flex-col w-screen">
                <header className="flex p-4 fixed">
                    <div className="flex flex-row w-full justify-between items-center">
                        <a href="/" id="burger">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                <path d="M24 6h-24v-4h24v4zm0 4h-24v4h24v-4zm0 8h-24v4h24v-4z"/>
                            </svg>
                        </a>
                        <a href="/" className="d-block">
                            logo
                        </a>
                    </div>
                </header>
            </div>
        </>
    )
}

export default HeaderMain;