/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"

import HeaderMain from "./template-parts/global/header"
import FooterMain from "./template-parts/global/footer"
import OffPageMenu from "./template-parts/navigation/offpage-menu"
import "../assets/styles.css"

const Layout = ({ children }) => {
    return (
        <>
            <main id="main" className="flex flex-col">
                <OffPageMenu/>
                <HeaderMain/>
                    <section className="entry-content">
                        {children}
                    </section>
                <FooterMain/>
            </main>
        </>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout