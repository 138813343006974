import React from 'react'

const FooterMenu = (props) => {
    return (
        <ul className="list-unstyled my-5 ml-0 list-stack">
            <li>Footer Menu</li>
        </ul>
    )
}

export default FooterMenu