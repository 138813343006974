import React from 'react'
import FooterMenu from "../../template-parts/navigation/footer"
import SocialBar from "../../template-parts/social/bar"

const FooterMain = (props) => {
    return (
        <footer className="bg-very_light_grey py-2 px-2">
            <div className="flex flex-row">
                <div className="flex flex-col w-1/4">
                    <FooterMenu/>
                </div>
                <div className="flex flex-col w-3/4">
                    <div className="w-100 flex justify-center my-5">
                        <h3 className="text-2xl font-bold">SPEAK TO US</h3>
                    </div>
                    <div className="flex flex-col">
                        <div className="w-100 text-center">
                            <p className="text-center">Based in Northamptonshire; 1 hour from London, 30 minutes from
                                Leicester, 25 minutes from
                                Peterborough...</p>
                            <p className="text-center">Or just a phone call away!</p>
                            <a href="tel:+4401536560435" className="flex justify-center"><span
                                className="font-semibold">+44 (0)1536 560 435</span></a>
                        </div>
                        <div className="w-100 d-flex flex-column my-4">
                            <SocialBar/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex flex-row justify-center">
                <p className="text-xs text-center">&copy; Copyright 2019 Wots The Big Idea - Corby Enterprise Centre,
                    London Road, Priors Hall, Corby, Northamptonshire, NN17 5EU - Tel: 01536 560 435 - Company Number:
                    7114544</p>
            </div>
        </footer>
    )
}

export default FooterMain