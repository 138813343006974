import React from 'react'

const SocialBar = (props) => {
    return (
        <ul className="list-unstyled flex justify-center social-bar">
            <li>Social bar</li>
        </ul>
    )
}

export default SocialBar